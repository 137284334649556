import React from "react";
import "./style/side-menu.scss";
import {ApiEngine} from "api-engine";
import MenuButton from "../menu_buttons/MenuButton";
import MenuButtonSkeleton from "../menu_buttons/MenuButtonSkeleton";
import {ApiContext} from "../../../domains/api/contexts/ApiContext";
import News from "../../header/news/common/News";
import ApiProvider from "../../../domains/api/providers/ApiProvider";
import UserSmallWidget from "../../../domains/actors/users/widgets/user_small_widget/UserSmallWidget";
import Backdrop from "../../../components/misc/backdrops/Backdrop/Backdrop";

interface SideMenuProps {
  api: ApiEngine
  user?: any
  participant?: any
}
interface SideMenuState {
  menuItems: any[] | null
  openMenu: boolean
  showTitles: boolean
}

export default class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  mount: Boolean = false;
  updateTimeout: any = null;
  constructor(_props: SideMenuProps) {
    super(_props);

    this.state = {
      menuItems: null,
      openMenu: false,
      showTitles: true
    };

    this.getData = this.getData.bind(this);
    this.triggerMenu = this.triggerMenu.bind(this);
  }

  triggerMenu() {
    this.setState({openMenu: !this.state.openMenu})
  }

  componentDidMount() {
    const me = this;
    if (this.mount) return;
    this.mount = true;
    document.addEventListener("TRIGGER_MENU", me.triggerMenu)
    me.getData();
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimeout);
  }

  getData() {
    const me = this;
    const url = "/api/users/menu";
    me.props.api.asyncFetch(url, {}).then((_res) => {
      me.setState({
        menuItems: _res.leftMenuButtons
      }, () => {
        this.updateTimeout = setTimeout(me.getData, 10000);
      });
    })
  }

  render() {
    const me = this;
    const minWidth = me.state.showTitles ? undefined : "30px"
    return <>
      <Backdrop open={me.state.openMenu}
                onClose={me.triggerMenu}/>

      <aside data-testid={"aside-menu"}
             style={me.state.openMenu ? {transform: "none", minWidth: minWidth} : {minWidth: minWidth}}
      >

        <ApiProvider>
          <ApiContext.Consumer>
            {
              ({api, user}) => {
                if (!user) return <p>Не авторизован</p>
                return <UserSmallWidget
                    showTitles={me.state.showTitles}
                    key={JSON.stringify(user)}
                    user={user}/>
              }
            }
          </ApiContext.Consumer>
        </ApiProvider>
        <hr style={{width: "100%"}}/>
        {me.state.menuItems ?
            me.state.menuItems.map((_btn, _btnIndex) => {
              return <MenuButton content={_btn}
                                 showTitle={me.state.showTitles}
                                 key={`menu-${_btnIndex}`}/>
            })
            : Array.from(Array(10).keys()).map((x) => {
              return <MenuButtonSkeleton/>
            })
        }
        <p onClick={() => {
          me.setState({
            showTitles: !me.state.showTitles
          })
        }} style={{cursor: "pointer"}}>Подписи</p>
      </aside>

    </>;
  }
}