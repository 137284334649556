import React from "react";
import "./style/backdrop.scss";
interface BackdropProps {
    open: boolean;
    onClose: any
}
export default class Backdrop extends React.Component<BackdropProps, any> {
    render() {
        const me = this;

        return <div onClick={(ev) => {
            ev.stopPropagation();
            if (me.props.onClose) me.props.onClose();
        }} className={`backdrop${me.props.open ? "-open" : ""}`}
        ></div>;
    }
}