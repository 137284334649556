import React from "react";
import "./style/user-small-widget.scss";

interface UserSmallWidgetProps {
  user: any;
  showTitles?: boolean
}

export default class UserSmallWidget extends React.Component<UserSmallWidgetProps, any> {
  render() {
    const me = this;
    if (!me.props.user.last_name) return <p>Ждем данные</p>
    return <div className={"user-small-widget"}>
      <div className={"initials"}>{me.props.user.last_name[0]}&nbsp;{me.props.user.first_name[0]}</div>
      { me.props.showTitles  &&
          <p className={"name"}>{me.props.user.last_name}&nbsp;{me.props.user.first_name[0]}.{me.props.user.middle_name[0]}.</p>
      }
    </div>;
  }
}