import React from "react";
import {Chart, PointElement, CategoryScale, LinearScale, LineElement, LineController} from "chart.js";
import 'chartjs-plugin-style';
import "./style/line-chart.scss";

interface LineChartProps {
    width?: string;
    showX?: boolean,
    showY?: boolean,
    title?: string,
    data: any
}

export default class LineChart extends React.Component<LineChartProps, any> {
    chartRef = React.createRef<HTMLCanvasElement>();
    mount: Boolean = false
    componentDidMount() {
        if (this.mount) return;
        this.mount = true;
        const me = this;
        const plugin = {
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart: any, args: any, options: any) => {
                const {ctx} = chart;
                ctx.save();
                let _stroke = ctx.stroke;
                ctx.stroke = function() {
                    ctx.save();
                    ctx.shadowColor = ctx.strokeStyle;
                    ctx.shadowBlur = 5;
                    ctx.fillOpacity = 0;
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 4;
                    // ctx.fillStyle  = "#384146";
                    // ctx.fill();
                    _stroke.apply(this, arguments);
                    ctx.restore();
                };
                ctx.restore();
            }
        };



        Chart.register(LineController);
        Chart.register(CategoryScale);
        Chart.register(LinearScale);
        Chart.register(PointElement);
        Chart.register(LineElement);
        Chart.register(LineController);

        const chart = new Chart(
            me.chartRef!!.current!!,
            {
                options: {
                    devicePixelRatio: 1,
                    scales: {
                        x: {
                            border: {
                              display: false
                            },
                            ticks: {
                              display: false
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            border: {
                                display: false
                            },
                            ticks: {
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        }
                    },
                    backgroundColor: "transparent"
                },

                plugins: [plugin],
                type: 'line',
                data: {
                    labels: me.props.data.map((row: any) => row.x),
                    datasets: [
                        {
                            tension: 0.3,
                            borderColor: "#00AA52",
                            label: me.props.title,
                            pointRadius: 0,
                            data: me.props.data.map((row: any) => row.y)
                        }
                    ]
                }
            });
    }
    render() {
        const me = this;
        return <div className={"line-chart"}
                    style={{display: "flex", minWidth: me.props.width , width: me.props.width}}
        >
            <canvas ref={me.chartRef} id={`chart`}></canvas>
        </div>
    }
}