import { ApiContext } from "../../domains/api/contexts/ApiContext";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import KanBan from "./KanBan";
import React from "react";

interface KanBanContainerProps {
    realm: string
}

interface KanBanContainerState {
}

export default class KanBanContainer extends React.Component<KanBanContainerProps, KanBanContainerState> {
    render() {
        const me = this
        return <ApiProvider>
            <ApiContext.Consumer>
              {
                ({api, user}) => {
                    return <KanBan api={api!!}
                                   realm={me.props.realm} />
                }
              }
            </ApiContext.Consumer>
        </ApiProvider>
    }
}