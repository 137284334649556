import React from "react";
import "./style/header.scss";
import News from "../news/common/News";
import ApiProvider from "../../../domains/api/providers/ApiProvider";
import { ApiContext } from "../../../domains/api/contexts/ApiContext";
import UserSmallWidget from "../../../domains/actors/users/widgets/user_small_widget/UserSmallWidget";

export default class Header extends React.Component<any, any> {
  render() {
    const me = this;
    return <header>
      <ApiProvider>
        <ApiContext.Consumer>
          {
            ({api, user}) => {
              return <>
                <div className={"xs-top"}>
                  { user &&
                      <UserSmallWidget key={JSON.stringify(user)} user={user} />
                  }
                  <i className={"fal fa-bars fa-2x"} onClick={() => {
                    const ev = new Event("TRIGGER_MENU");
                    document.dispatchEvent(ev);
                  }}/>
                </div>
                <div className={"news"}>
                  <News api={api!!} />
                </div>
              </>
            }
          }
        </ApiContext.Consumer>
      </ApiProvider>
    </header>;
  }
}