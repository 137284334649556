import React from "react";
import "./style/main_metric_widget.scss";
import LineChart from "../charts/line_chart/LineChart";

interface MainMetricWidgetProps {
    value: any
    prevDelta?: number
    caption: any
    chartData?: any
}

export default class MainMetricWidget extends React.Component<MainMetricWidgetProps, any> {
    render() {
        const me = this;
        return <div className={"main-metric-widget"}
                    style={me.props.chartData ? {background: "var(--blue-gray)"} : {}}>
            <div className={"content"}>
                <p className={"value"}>{me.props.value.toLocaleString()}</p>
                <p className={"caption"}>{me.props.caption}</p>
                { me.props.prevDelta &&
                    <p className={"prev-delta"}>{me.props.prevDelta > 0 ? "+" : "-"}{me.props.prevDelta}%</p>
                }
            </div>
            { me.props.chartData &&
                <LineChart data={me.props.chartData} width={"200px"} />
            }
        </div>
    }
}