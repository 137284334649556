import React from "react";
import "./style/news-article.scss";

interface NewsArticleProps {
  newsArticle: any;
}

export default class NewsArticle extends React.Component<NewsArticleProps, any> {
  render() {
    const me = this;
    return <div className={"news-article"} onClick={(e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
    }}>
      <h1>{me.props.newsArticle.title}</h1>
      <h2>{me.props.newsArticle.lead}</h2>
      <div dangerouslySetInnerHTML={{__html: me.props.newsArticle.body}}></div>
    </div>
  }
}