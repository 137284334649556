import React from "react";
import "./style/main.scss";
import Header from "../../header/common/Header";
import Demo from "../../../pages/demo/Demo";
import {redirect, Route, Router, Navigate, Routes} from "react-router-dom";
import Search from "../../../components/searches/Search";
import MainMetricsPart from "../main_metrics/MainMetricsPart";

export default class Main extends React.Component<any, any> {
  render() {
    return <main>

      <Header/>
      <Search/>
      <MainMetricsPart/>

      <Routes>
        <Route path="/*"
                index={true}
                element={<Demo />}
        />
      </Routes>
    </main>
  }
}