import React from "react";
import "../common/style/story-square.scss";

interface StorySquareSkeletonProps {
  count: number
}

export default class StorySquareSkeleton extends React.Component<StorySquareSkeletonProps, any > {
  render() {
    const me = this;
    const res = [] as any;
    for(let i = 0; i < me.props.count; i++) {
      res.push(<div data-testid={"story-square-skeleton"} className={"story-square-skeleton"}></div>)
    }
    return <>{res}</>
  }
}