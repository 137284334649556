export default class User {
  last_name: string
  first_name: string
  middle_name: string
  email: string
  cell: string

  constructor(_props: any) {
    this.last_name = _props.last_name;
    this.first_name = _props.first_name;
    this.middle_name = _props.middle_name;
    this.email = _props.email;
    this.cell = _props.cell;
  }
}