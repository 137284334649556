import React from "react";
import "./style/menu-button.scss";

interface MenuButtonProps {
  content: any;
  showTitle: boolean;
}
interface MenuButtonState {
}

export default class MenuButton extends React.Component<MenuButtonProps, MenuButtonState> {
  render() {
    const me = this;
    return <div className={"menu-button"}>
      <i className={me.props.content.faIcon}/>
      { me.props.showTitle &&
        <span className={"title"}>{me.props.content.title}</span>
      }
          <span className={"amount"}>{me.props.content.amount}</span>
    </div>;
  }
}