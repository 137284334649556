import React from "react";
import ModalWindow from "../../layout/modal/ModalWindow";
import "./style/login-form.scss";
import {IMaskInput} from "react-imask";
import {ApiEngine} from "api-engine";

interface LoginWindowProps {
  api: ApiEngine
}

interface LoginWindowState {
  login: string,
  password: string
}

function filterCell(cell: string): string {
  return cell.replaceAll("(",")").
  replaceAll(")","").
  replaceAll("-", "").
  replaceAll("+", "");
}

export default class LoginWindow extends React.Component<LoginWindowProps, LoginWindowState> {
  passwordInput: React.RefObject<HTMLInputElement> = React.createRef()
  constructor(_props: LoginWindowProps) {
    super(_props);
    this.state = {
      login: "",
      password: ""
    }
    this.checkData = this.checkData.bind(this);
  }

  checkData() {
    const me = this;
    const parcel = {
      cell: filterCell(me.state.login),
      password: me.state.password
    };
    me.props.api.asyncFetch("/api/users/auth/sign_in", {
      method: "POST", body: JSON.stringify(parcel)
    }).then((res) => {
      if (res.jwt) {
        localStorage.setItem("jwt", res.jwt);
        document.location.reload();
      }
    });
  }

  render() {
    const me = this;
    return <ModalWindow onClick={null}>
      <div className={"login-form"}>
        <h2>Вход</h2>
        <IMaskInput mask={"+{7}(000)000-00-00"}
         radix="."
         placeholder={"Номер телефона"}
          type={"tel"}
         value={me.state.login}
         onComplete={(value: any, mask: any) => {
           me.setState({
             login: value
           }, () => {
             me.passwordInput.current?.focus()
           })
         }}/>
        <input ref={me.passwordInput}
               onChange={(e) => {
                 me.setState({
                   password: e.target.value
                 })
               }}
               placeholder={"Пароль"}
               type={"password"}/>
        { me.state.login && me.state.password && <button onClick={me.checkData} className={"green"}>ВОЙТИ</button> }
      </div>
    </ModalWindow>
  }
}