import React from "react";
import "./style/searches.scss";

export default class Search extends React.Component<any, any> {
    render() {
        return <div className={"search"}>
            <div className={"search-container"}>
                <i className={"fal fa-search"} />
                <input
                    placeholder={"Поиск"}
                    className={"search-input"} />
                <button>
                    <i className={"fas fa-star"}/>
                    избранное
                </button>
            </div>
        </div>
    }

}