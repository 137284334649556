import React from "react";
import "./style/main_metrics.scss";
import MainMetricWidget from "../../../components/metric_widgets/MainMetricWidget";

const metrics = [
    {value: 2700, caption: "Капучино"},
    {value: 700, caption: "Эспрессо"},
    {value: 270, caption: "Латте"},
    {value: 32, caption: "Раф"},
    {value: 32, caption: "Два"},
    {value: 32, caption: "Три"},
    {value: 12700, caption: "3 в 1"},
]
export default class MainMetricsPart extends React.Component<any, any> {
    render() {
        return <div className={"main-metrics"}>
            { metrics.map((_metric, _metricIndex) => {
                return <MainMetricWidget key={`metric_${_metricIndex}`} value={_metric.value} caption={_metric.caption} />
            })}
        </div>
    }
}