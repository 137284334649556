import React from "react";
import "./style/menu-button.scss";

interface MenuButtonSkeletonProps {}
interface MenuButtonSkeletonState {}
export default class MenuButtonSkeleton extends React.Component<MenuButtonSkeletonProps, MenuButtonSkeletonState> {
  render() {
    const me = this;
    return <div className={"menu-button"}>
      <span className={"title-skeleton"} style={{display: "flex", width: "60%"}}></span>
      <span className={"amount-skeleton"}></span>
    </div>;
  }
}